<template>
    <v-overlay>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
</template>

<script>
import { mapActions } from 'vuex';

export default {
    name: 'login-success',
    data: () => ({}),
    mounted() {
        this.findMyProfile();
    },
    computed: {},
    methods: {
        ...mapActions(['findMyProfile']),
    },
};
</script>
